import dayjs, { Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import updateLocale from 'dayjs/plugin/updateLocale';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(advancedFormat);
dayjs.extend(isBetweenPlugin);
dayjs.extend(utc);
dayjs.extend(weekOfYear);
dayjs.extend(updateLocale);
dayjs.extend(weekday);

dayjs.updateLocale('en', {
    // Sunday = 0, Monday = 1.
    weekStart: 1,
});

export { dayjs, Dayjs };

export const defaultDateFormat = 'DD/MM/YYYY';
export const isoDateFormat = 'YYYY-MM-DD';

/**
 * Converts string date into dayjs object, returns current date if no date provides.
 * Can use instead of dayjs()
 *
 * @param { Dayjs | string | undefined} date selected/ active tab `@default dayjs()`
 * @param { string | undefined } format format of string date, `@default undefined`
 */
export const getDate = (date?: string, format?: string): Dayjs =>
    dayjs(date || dayjs(), format);

/**
 * Converts date into string fotmatted date
 *
 * @param { Dayjs | string | number } date date object or string date `@default dayjs()`
 * @param { string } format string format `@default DD/MM/YYYY`
 */
export const getFormattedDate = (
    date: Dayjs | string | number = dayjs(),
    format = defaultDateFormat,
) => dayjs(date).format(format);

/**
 * Converts date into ISO string fotmat. `@eg 1970/12/01`
 *
 * @param { Dayjs | string | number } date date object or string date `@default dayjs()`
 *
 */
export const getISOFormattedDate = (date: Dayjs | string | number = dayjs()) =>
    dayjs(date).format(isoDateFormat);
